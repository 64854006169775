import React from "react";
import { Header, Divider } from "semantic-ui-react";
import Image from "../components/optimized-image";

const PlaceHolderContent = ({ title }) => (
  <div style={{ paddingTop: "150px" }}>
    <Header as="h1">{title || "TITLE"}</Header>
    <Divider />
    <Image filename="paragraph.png" />
    <Divider />
    <Image filename="paragraph.png" />
    <Divider />
    <Image filename="paragraph.png" />
    <Divider />
    <Image filename="paragraph.png" />
    <Divider />
    <Image filename="paragraph.png" />
    <Divider />
  </div>
);

export default PlaceHolderContent;
