import React from "react";
import { Container } from "semantic-ui-react";

import LayoutHome from "../components/layout/layout-home";
import SEO from "../components/seo/seo";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";
import PlaceHolderContent from "../components/placeholder-content";

const Page = () => (
  <LayoutHome>
    <SEO title="Camps" keywords={[`rocksteady`]} />

    <Container>
      <SecondaryNavBar
        product="camps"
        title="Rocksteady Holiday Camps"
        links={[]}
        cta="Book your place"
      />
      <PlaceHolderContent title="Primary Parents Info" />
    </Container>
  </LayoutHome>
);

export default Page;
